import {IdentitiesWrapperModal} from "../common/identitiesWrapperModal/IdentitiesWrapperModal";
import {ReactElement, useEffect, useState} from "react";
import {getCompaniesForGlobalUser} from "../../services/CompanyService";
import {Toast} from "@eazy2biz-ui/common-components";
import {ERROR_STRINGS} from "../../../contents/DisplayContent";
import styles from './CompanySelectionModal.module.css';
import {CompanyResponse, GlobalUserCompanyMembership} from "@eazy2biz/common-util";
import {getFormattedDate} from "@eazy2biz/common-package-ui";

/**
 * Selecting Company
 * @param props
 */
const CompanySelectionModal: (props: PropsType) => JSX.Element = (props: PropsType): JSX.Element => {

    const [companyArray, setCompanyArray] = useState<CompanyResponse[]>([]);
    useEffect(() => {
        fetchCompanyDetails();
    }, []);

    const fetchCompanyDetails = async () => {
        getCompaniesForGlobalUser().then((response : CompanyResponse[]) => {
            setCompanyArray(response);
        }).catch((e) => Toast.error(e, ERROR_STRINGS.ERROR_FETCHING_COMPANY_DETAILS));
    };

    const handleOnCompanySelect = (companyId: number) => {
        const membership = props.companyMemberships.find((m) => m.companyId === companyId);
        membership && props.onCompanySelect(membership);
    };

    const renderCompanyCard = (company: CompanyResponse) => {

        return (
            <div className={styles.companyCardWrapper} key={company._id} onClick={() => handleOnCompanySelect(company._id)}>
                <div className={styles.companyNameText}>
                    {company.details.name}
                </div>
                <p className={styles.companySubText}>{'Created on ' + getFormattedDate(company.metaData.createdDate)}</p>
            </div>
        );
    };

    const renderCompanyIds = (): ReactElement => {
        return (
            <div className={styles.companyCardBox}>
                {companyArray.map(renderCompanyCard)}
            </div>
        );
    };

    return (
        <IdentitiesWrapperModal
            title={'Select Company'}
            onClose={props.onClose}
            getBody={renderCompanyIds}
        />
    );
}

type PropsType = {
    onCompanySelect: (companyMembership: GlobalUserCompanyMembership) => void;
    onClose: () => void;
    companyMemberships: GlobalUserCompanyMembership[];
};

export default CompanySelectionModal;
