import React, {useState} from "react";
import {Nav, Navbar} from "react-bootstrap";
import styles from './TopNavigationBar.module.css';
import {Button, Dropdown, Menu} from "antd";
import {ExitIcon, PersonIcon, SymbolIcon, PlusIcon} from "@radix-ui/react-icons";
import {NavBar} from "../../../../contents/DisplayContent";
import {getCurrentCompanyName, getCurrentUserName} from "@eazy2biz/common-package-ui";
import {UserProfileAvatar} from "@eazy2biz-ui/common-components";
import {ProfileDrawer} from "@eazy2biz-ui/identities-ui";
import { getCurrentGlobalUser, RoutesEnum } from "@eazy2biz-ui/common-package";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

const TopNavigationBar: (props: PropTypes) => JSX.Element = (props: PropTypes) => {
    const [drawerVisible, toggleDrawerVisibility] = useState<boolean>(false);
    const currentGlobalUser = getCurrentGlobalUser();
    const history = useHistory();

    const userName = getCurrentUserName();

    const toggleProfileDrawer = () => {
        toggleDrawerVisibility(!drawerVisible);
    };

    const switchCompany = () => {
      history.push(RoutesEnum.SWITCH_COMPANY.toString());
    };

    const createCompany = () => {
      history.push(RoutesEnum.CREATE_COMPANY.toString());
    };

    const menu = (
        <Menu>
            <Menu.Item icon={<PersonIcon />} onClick={toggleProfileDrawer}>
                {NavBar.PROFILE}
            </Menu.Item>
            <Menu.Item icon={<PlusIcon />} onClick={createCompany}>
                {NavBar.CREATE_COMPANY}
            </Menu.Item>
          {
            get(currentGlobalUser, 'companyMemberships', []).length > 1 &&
            <Menu.Item icon={<SymbolIcon />} onClick={switchCompany}>
              {NavBar.SWITCH_COMPANY}
            </Menu.Item>
          }
            <Menu.Item icon={<ExitIcon />} onClick={props.onSignOut}>
                {NavBar.SIGN_OUT}
            </Menu.Item>
        </Menu>
    );

    return(
        <Navbar className={styles.navbarContentsStyle} collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand className={styles.topNavBarBrand} href="/">
                <span>{getCurrentCompanyName()}</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className={styles.responsive_navbar_nav}>
                <Nav className="me-auto"/>
                <Nav className={styles.navbar}>
                    <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
                        <Button className={styles.dropdownBtn} type={'ghost'}>
                            <div className={styles.user_name_logo}>
                            <UserProfileAvatar />
                            <span className={styles.userName}>{userName}</span>
                            </div>
                        </Button>
                    </Dropdown>
                </Nav>
            </Navbar.Collapse>
            <ProfileDrawer isVisible={drawerVisible} onDrawerClose={toggleProfileDrawer}/>
        </Navbar>
    );
};

type PropTypes = {
    toggleSideMenu: () => void;
    removeAllCookies: () => void;
    onSignOut: () => void;
};

export default TopNavigationBar;
