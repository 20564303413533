import { useHistory } from "react-router-dom";
import { GlobalUserCompanyMembership } from "@eazy2biz/common-util";
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import { Spinner, Toast } from "@eazy2biz-ui/common-components";
import { useState } from "react";
import { SIGN_IN_STRINGS } from "../../../contents/DisplayContent";
import { handleCompanySignIn } from "../../helpers/AuthHelper";
import {CompanySignUpComponent} from "./CompanySignUpComponent";

/**
 * Switch Company Form.
 * @param props
 */
export const CreateCompanyForm: () => JSX.Element = (): JSX.Element => {
  // TODO FIX THIS COMPONENT.
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(false);

  const redirectToApp = () => {
    setShowSpinner(true);
    setTimeout(() => {
      history.replace(RoutesEnum.APP_DASHBOARD);
      Toast.success(SIGN_IN_STRINGS.LOGGED_IN_COMPANY);
    }, 1000);
  };

  const handleCompanySelect = (globalUserCompanyMembership: GlobalUserCompanyMembership): void => {
    Toast.load(SIGN_IN_STRINGS.FETCHING_COMPANY);
    handleCompanySignIn(globalUserCompanyMembership).then(redirectToApp);
  };

  if (showSpinner) {
    return (<Spinner/>);
  }

  return (
    <CompanySignUpComponent
      onCompanySelect={handleCompanySelect}
      onClose={history.goBack} />
  );
};
